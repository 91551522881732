import React from 'react'
import { MAIN_PHONE_NUMBER } from '@constants/index'
import { ExternalPaths } from '@shared/menu-items/paths'
import FullLogo from '@shared/svg/full-logo.svg'
import SkolkovoLogo from '@shared/svg/skolkovo.svg'
import cn from 'classnames'
import Link from 'next/link'

import { useRegions } from '../../../../regions/provider'
import { Socials } from '../../socials'

import styles from './style.module.scss'

export const WowFooter = ({
	extraContent,
	hideTopContent,
}: {
	extraContent?: React.ReactNode
	hideTopContent?: boolean
}) => {
	const regions = useRegions()

	return (
		<footer>
			{!hideTopContent && (
				<>
					<section className={styles.wowTop}>
						<Link href='/' className={styles.logoWrap}>
							<FullLogo className={styles.logo} />
						</Link>
						<Socials className={styles.wowSocials} withoutOD />
						<div className={cn(styles.contacts, styles.wowContacts)}>
							<a href={`tel:${MAIN_PHONE_NUMBER.replace(/\s/g, '')}`}>{MAIN_PHONE_NUMBER}</a>
							<p className={styles.smallText}>Звонки по России бесплатно</p>
						</div>
					</section>
					<section className={styles.wowContent}>
						<div className={styles.wowBlock}>
							<p className={styles.lightText}>
								Услуги физическим лицам оказывает Кредитный потребительский кооператив «Фонд
								Ипотечного Кредитования» (620075, Россия, Свердловская обл, г.о. город Екатеринбург,
								г Екатеринбург, ул Малышева, стр. 51, офис 25/06/03, ОГРН 1196658040677, ИНН
								6678102434, официальный сайт{' '}
								<a className='main-link' href='https://loanfund.ru/'>
									https://loanfund.ru/
								</a>
								) в рамках Федерального закона № 190-ФЗ от 18.07.2009 года путем заключения договора
								займа на следующих условиях.
							</p>
							<br />
							<p className={styles.lightText}>
								Сумма займа от 200 000 до 15 000 000 рублей. Процентная ставка за пользование займом
								— от 13,9% до 16,9% годовых в зависимости от выбранной программы выдачи займов,
								процентная ставка может изменяться в указанном диапазоне в случае наличия
								задолженности по внесению платежей. Минимальный срок займа — 12 месяцев.
								Максимальный срок займа — 120 месяцев. Платежи по оплате основного долга и процентов
								по займу подразделяются на 3 вида (аннуитетный, процентный, смешанный), и
								определяются индивидуальными условиями договора займа. Помимо указанных платежей
								заемщик платит членские взносы, состоящие из двух частей – Постоянного членского
								взноса начисленного при выдаче займа и Переменного членского взноса (предусмотрен не
								во всех программах), начисляемого в зависимости от остатка задолженности по Займу
								(Основному долгу) и подлежащему ежемесячному внесению в день платежа по займу.
							</p>
							<br />
							<p className={styles.lightText}>
								Размер постоянного членского взноса определяется по формуле: (срок займа в месяцах)
								* (сумма займа) * 0,005-2% (в зависимости от выбранной программы выдачи займов).
							</p>
						</div>

						<div className={styles.wowBlock}>
							<p className={styles.lightText}>
								Размер Переменного членского взноса (в случае его наличия) составляет 8,9% - 12% (в
								зависимости от выбранной программы выдачи займов) годовых на сумму остатка основного
								долга по договору займа.
							</p>
							<br />
							<p className={styles.lightText}>
								Полная стоимость займа составляет от 13,9 до 50% годовых в зависимости от
								индивидуальных условий займа. Размер неустойки за ненадлежащее исполнение условий
								договора 0,054% в день от суммы просроченной задолженности.
							</p>
							<br />
							<p className={styles.lightText}>
								Обеспечение исполнения обязательств - залог жилого недвижимого имущества, возможно
								поручительство физического лица.
							</p>
							<br />
							<p className={styles.lightText}>
								Кредитный потребительский кооператив оказывает услуги исключительно членам
								кредитного кооператива (пайщикам). При вступлении в кооператив вносится обязательный
								паевой взнос в размере 50 рублей.
							</p>
							<br />
							<p className={styles.lightText}>
								Член кредитного кооператива (пайщика) обязаны нести субсидиарную ответственность по
								обязательствам кооператива в пределах невнесенной части дополнительного взноса
								каждого из членов кредитного кооператива (пайщиков).
							</p>
						</div>
					</section>
				</>
			)}
			<div>{extraContent && extraContent}</div>
			<section>
				<div className={cn(styles.wowCookiesInfo, styles.lightText)}>
					Мы&nbsp;используем Cookies для сбора обезличенных данных. Они полезны для статистики,
					анализа трафика и&nbsp;настройки подходящей рекламы. Оставаясь на&nbsp;сайте,
					вы&nbsp;соглашаетесь на&nbsp;сбор таких данных.
				</div>

				<div className={styles.wowWrapper}>
					<span className={cn(styles.lightText, styles.wowCopyright)}>
						© 2019—{new Date().getFullYear()} ООО «Кредит.Клаб»
						<br />
						ОГРН 1196658084743
						<br />
						ИНН 6678105594
						<br />
						620075, Россия, Свердловская&nbsp;обл., Екатеринбург, ул.&nbsp;Малышева, стр.&nbsp;51,
						офис&nbsp;26/14
						<br />
						Платформа цифровых решений. Модули программы Информационной системы Credit.Club
						предоставлены по лицензионным договорам КПК «Фонд Ипотечного Кредитования» и ООО МКК
						«Магазин кредитов»
						<br />
						Сервис не является деятельностью оператора инвестиционной платформы по содействию в
						инвестировании и в предоставлении займов с использованием инвестиционной платформы,
						предоставляются технические возможности модулей программы для ЭВМ.
					</span>

					<div className={cn(styles.wowRegions, styles.mobileRegions)}>
						<a className={cn(styles.lightText, 'main-link')} href='mailto:platform@credit.club'>
							platform@credit.club
						</a>
						<span className={styles.currentCity} onClick={regions.openPicker}>
							{regions.currentCity?.displayName}
						</span>
					</div>
				</div>

				<a
					href={ExternalPaths.skolkovo}
					target='_blank'
					rel='noopener noreferrer nofollow'
					className={styles.logosItem}
				>
					<SkolkovoLogo className={cn(styles.logosSkolkovo, styles.logosLogo)} />
					<span>Участник проекта инновационного центра «Сколково»</span>
				</a>
				<div className={cn(styles.wowRegions, styles.desktopRegions)}>
					<a className={cn(styles.lightText, 'main-link')} href='mailto:platform@credit.club'>
						platform@credit.club
					</a>
					<span className={styles.currentCity} onClick={regions.openPicker}>
						{regions.currentCity?.displayName}
					</span>
				</div>
			</section>
		</footer>
	)
}
